import { useEffect, useState } from "react";
import { getAirportDetails } from "../app/services/aerocharter";
import { setStateVisitPage } from "../components/helpers";

export const useServicesType = ({ globalState, middleWareDispatcher }) => {
  const { quote } = globalState;
  const [disableSender, setSenderFieldsDisabled] = useState(false);
  const [disableReceiver, setReceiverFieldsDisabled] = useState(false);

  useEffect(() => {
    setStateVisitPage(false);
    const serviceType = quote?.params?.serviceType || false;
    if (serviceType !== false) {
      const getAirportDetailsAsync = async (subjectStep, airport) => {
        const response = await getAirportDetails(airport).catch((err) => {
          return err;
        });
        if (!response.error) {
          const actionType = `APP/CAPTURE_${subjectStep.toUpperCase()}`;
          if (subjectStep === "senderDetails") {
            setSenderFieldsDisabled(true);
            // dirty fix to pick the correct airport state
            middleWareDispatcher({
              type: actionType,
              payload: { ...response, state: quote.params.origin },
            });
          } else if (subjectStep === "receiverDetails") {
            setReceiverFieldsDisabled(true);
            // dirty fix to pick the correct airport state
            middleWareDispatcher({
              type: actionType,
              payload: {
                ...response,
                state: quote.params.destination,
              },
            });
          }
        }
      };
      switch (serviceType) {
        case "AA":
          getAirportDetailsAsync("senderDetails", "senderDetails");
          getAirportDetailsAsync("receiverDetails", "receiverDetails");
          break;
        case "PA":
          getAirportDetailsAsync("receiverDetails", "receiverDetails");
          break;
        case "AP":
          getAirportDetailsAsync("senderDetails", "senderDetails");
          break;
        default:
          break;
      }
    }
  }, [
    quote.params.serviceType,
    setSenderFieldsDisabled,
    setReceiverFieldsDisabled,
    middleWareDispatcher,
  ]);
  return { disableSender, disableReceiver };
};
