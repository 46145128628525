import { ArrowLeftIcon } from "@heroicons/react/outline"
import { Link, navigate } from "gatsby"
import * as React from "react"
import { useState, useRef, useEffect } from "react"
import { useStore } from "../app/store/store"
import SubjectDetails from "../components/forms/subject-details"
import Layout from "../components/global/layout"
import Navbar from "../components/global/navbar"
import PageTitle from "../components/global/page-title"
import * as validate from "validate.js"
import { shippingFieldsValidation } from "../app/services/validation"
import { Transition } from "@headlessui/react"
import { Errors } from "../components/global/Errors"
import { Button } from "../components/global/Button"
import { useServicesType } from "../hooks/useServicesType"
import { validateSender } from "../app/services/aerocharter"
import Modal from "../components/forms/modal"

const RealizarEnvio = ({ location }) => {
  const [globalState, middleWareDispatcher] = useStore()
  const [step, setStep] = useState("senderDetails")
  const [currentStepTitle, setStepTitle] = useState("¿Quién envía?")
  const [fieldsErrors, setFieldsErrors] = useState("")
  const [showModal, setShowModal] = useState(false)
  const formRef = useRef(null)
  const anchorRef = useRef(null)

  useEffect(() => {
    if (location.state && location.state.step) {
      switchState(location.state.step)
    }
  }, [location, setStep])

  const { disableSender, disableReceiver } = useServicesType({
    globalState,
    middleWareDispatcher,
  })

  const switchState = (step = "senderDetails") => {
    setStep(step)
    setStepTitle(
      step === "senderDetails" ? "¿Quién envía?" : "¿Quién recibe?"
    )
  }
  const handleBack = (e) => {
    e.preventDefault()
    if (step === 'senderDetails') {
      return navigate("/detalles-de-la-cotizacion")

    } else {
      switchState(step === 'receiverDetails' ? 'senderDetails' : '')
    }
  }

  const handleArrowClick = async (event) => {
    event.preventDefault()
    const { name } = event.currentTarget
    validateFields()
      .then(async (areFieldsValid) => {
        if (areFieldsValid !== true) return
        switchState(name)
        if (name === "receiverDetails" && step === "receiverDetails") {
          const { message } = await validateSender({
            receiver: globalState?.receiverDetails?.postalcode,
            sender: globalState?.senderDetails?.postalcode,
          })
          if (message === "servicio disponible") {
            const timer = setTimeout(() => {
              navigate("/realizar_pago")
            }, 1000)
            return () => clearTimeout(timer)
          } else {
            setShowModal(true)
          }
        }
        // console.log('SSS', name)
        if (name === "senderDetails" && step === "senderDetails") {
          navigate("/detalles-de-la-cotizacion")
        }
      })
      .catch((err) => {
        console.error("error validating fields", err)
      })
    anchorRef.current.scrollIntoView({
      behavior: "smooth",
      alignToTop: true,
    })
  }

  const handleSubmit = (event) => {
    console.log('evve', event)
    const fieldsValidated = validate(
      globalState.receiverDetails,
      shippingFieldsValidation
    )
    if (fieldsValidated !== null && fieldsValidated !== undefined) {
      setFieldsErrors(Object.values(fieldsValidated))
    } else {
      setFieldsErrors("")
    }
    event.preventDefault()
  }

  const handleDetailsChange = (event) => {
    let { name, value } = event.target
    if (name === "state") {
      if (step === "senderDetails" && globalState.quote.params.origin) {
        value = globalState.quote.params.origin
      } else if (step === "receiverDetails" && globalState.quote.params.destination) {
        value = globalState.quote.params.destination
      }
    }
    const actionType = `APP/CAPTURE_${step.toUpperCase()}`
    middleWareDispatcher({ type: actionType, payload: { [name]: value } })
  }

  async function validateFields() {
    let fieldsValidated, zipValidation, cp, state
    try {
      cp = step === "receiverDetails" ? globalState.receiverDetails.postalcode : globalState.senderDetails.postalcode
      state = step === "receiverDetails" ? globalState.quote.params.destination : globalState.quote.params.origin

      fieldsValidated = validate(
        step === "receiverDetails"
          ? globalState.receiverDetails
          : globalState.senderDetails,
        shippingFieldsValidation
      )

      if (fieldsValidated !== null && fieldsValidated !== undefined) {
        setFieldsErrors(Object.values(fieldsValidated))
        return Promise.resolve(Object.values(fieldsValidated))
      }

      zipValidation = await validate
        .async(
          {
            zipValidation: {
              postalCode: cp,
              state: state,
            },
          },
          {
            zipValidation: { cpValidation: true },
          }
        )
        .then((result) => result)
        .catch((err) => err)

      if (
        zipValidation !== null &&
        zipValidation !== undefined &&
        zipValidation?.zipValidation[0] !== true
      ) {
        zipValidation.zipValidation = zipValidation.zipValidation.map((el) =>
          el.replace("Zip validation ", "")
        )
        setFieldsErrors(Object.values(zipValidation))
        return Promise.resolve(Object.values(zipValidation))
      }

      setFieldsErrors("")
      return Promise.resolve(true)
    } catch (error) {
      return Promise.resolve(false)
    }
  }


  return (
    <>
      <Navbar />
      <Layout>
        <span ref={anchorRef}></span>
        <PageTitle>{currentStepTitle}</PageTitle>
        {/* <Modal /> */}
        {
          showModal && <Modal />
        }
        <form
          className="mt-4 mb-8 md:mx-6 pb-16"
          onSubmit={handleSubmit}
          ref={formRef}
          onChange={handleDetailsChange}
        >
          <Errors fieldsErrors={fieldsErrors} />
          {step === "senderDetails" && (
            <Transition
              enter="transition-all duration-300 ease-in-out"
              enterFrom="opacity-0 translate-x-4"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300 ease-in-out"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-4"
              show={step === "senderDetails"}
              appear={true}
            >
              <SubjectDetails
                step={step}
                details={globalState.senderDetails}
                active={!disableSender}
              />
            </Transition>
          )}
          {step === "receiverDetails" && (
            <Transition
              enter="transition-all duration-300 ease-in-out"
              enterFrom="opacity-0 translate-x-4"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300 ease-in-out"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-4"
              show={step === "receiverDetails"}
              appear={true}
            >
              <SubjectDetails
                step={step}
                details={globalState.receiverDetails}
                active={!disableReceiver}
              />
            </Transition>
          )}
          <div className="flex justify-center space-x-1 mt-4">
            <Button
              className="btn-shape text-white w-auto px-4 bg-primary-light-red hover:bg-opacity-90"
              handleClick={handleBack}
              name="senderDetails"
              text={'Atras'}
            />
            <Button
              text={"Siguiente"}
              handleClick={handleArrowClick}
              iconDirection={true}
              name="receiverDetails"
            />
          </div>
          {
            showModal && <Modal />
          }
        </form>
      </Layout>
    </>
  )
}

export default RealizarEnvio
